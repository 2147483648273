/* Main Layout */
.product-main {
  @apply w-full;
}

/* Home Section */
.home-section {
  @apply pt-2 *:lg:overflow-hidden bg-cyan-500 dark:animate-color-change-2x-dark;
}

/* Overview Section */
.overview-section {
  @apply relative pt-6 animate-color-change-2x dark:animate-color-change-2x-dark;
}

/* Features Section */
.features-section {
  @apply relative animate-color-change-2x dark:animate-color-change-2x-dark;
}

/* Pricing Section */
.pricing-section {
  @apply relative items-center animate-color-change-2x dark:animate-color-change-2x-dark;
}

.pricing-container {
  @apply py-4 w-full;
}

.pricing-content {
  @apply text-center px-4 sm:px-6 lg:px-8;
}

/* Contact Section */
.contact-section {
  @apply border-t-2 border-t-cyan-400 dark:bg-cyan-500 dark:bg-gradient-to-r dark:from-cyan-300 pb-2;
}
/* AuthenticatedLoader Styles */
.auth-loader-container {
  @apply flex flex-col justify-center items-center animate-color-change-2x dark:animate-color-change-2x-dark h-screen;
}

.auth-loader-content {
  @apply items-center animate-pulse cursor-pointer;
}

.auth-loader-logo {
  @apply h-24 mx-auto w-auto;
}

.auth-loader-title {
  @apply mt-0 text-center text-3xl font-bold text-gray-600 dark:text-gray-200;
}

.auth-loader-version {
  @apply inline align-middle text-xs tracking-tight text-cyan-300 pl-1;
}

/* ContactUs Layout */
.contact-image-container {
  @apply md:absolute md:left-0 md:w-1/2 lg:absolute lg:left-0 lg:w-1/2;
}

.contact-content-wrapper {
  @apply relative px-4 sm:px-6;
}

.contact-content {
  @apply md:ml-auto md:w-1/2 md:pl-10;
}

.contact-matrix-wrapper {
  @apply px-12 py-4 m-2 border-2 border-gray-600 rounded cursor-pointer;
}

/* Header Section */
.contact-spacer {
  @apply font-semibold text-cyan-300 align-middle;
}

.contact-title {
  @apply text-gray-600 dark:text-white ~text-3xl/5xl font-bold tracking-tight sm:tracking-tight;
}

.contact-description {
  @apply mt-4 text-xl text-gray-600 dark:text-gray-100;
}

/* Links Section */
.contact-links {
  @apply md:my-6 md:w-full md:flex md:flex-wrap md:~gap-x-2/4 md:gap-y-2;
}

/* Link Button Base Styles */
.contact-link {
  @apply flex md:inline-flex items-center text-center px-3 py-2 border border-transparent text-base font-medium;
}

.contact-link-docs {
  @apply contact-link text-indigo-400 hover:text-indigo-500;
}

.contact-link-docs-icon {
  @apply -ml-1 mr-1 h-5 w-5 text-indigo-500;
}

.contact-link-youtube {
  @apply contact-link text-cyan-400 hover:text-cyan-300 dark:text-red-400 dark:hover:text-red-500;
}

.contact-link-youtube-icon {
  @apply -ml-1 mr-1 h-5 w-5 text-cyan-500 dark:text-red-400;
}

.contact-link-demo {
  @apply contact-link text-purple-400 hover:text-purple-500;
}

.contact-link-demo-icon {
  @apply -ml-1 mr-1 h-5 w-5 text-purple-500;
}

.contact-link-chat {
  @apply contact-link pl-3 pr-1 rounded-md text-pink-500 hover:text-pink-500;
}

.contact-link-chat-icon {
  @apply -ml-1 mr-1 h-5 w-5 text-pink-500;
}

.contact-chat-arrow {
  @apply h-5 w-6 mt-2 text-pink-400;
}

/* AWS Marketplace Link */
.contact-aws-link {
  @apply inline ~px-1/3 ~py-1/2 dark:text-cyan-100 text-gray-500 text-center items-center ~text-sm/lg;
}

.contact-aws-image {
  @apply ml-2 m-2 ~h-5/6 inline;
}

/* Features Grid Layout */
.features-container {
  @apply relative items-center;
}

.features-header {
  @apply text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl;
}

.features-title {
  @apply text-lg font-semibold text-cyan-500;
}

.features-subtitle {
  @apply mt-2 text-3xl font-bold text-pink-400 dark:text-gray-200 tracking-tight sm:text-4xl sm:tracking-tight;
}

.features-description {
  @apply mb-10 mt-5 mx-auto max-w-prose text-xl text-gray-500;
}


/* Main Navigation */
.nav-header {
  @apply sticky w-full top-0 z-10 animate-color-change-2x dark:animate-color-change-2x-dark shadow-lg;
}

.nav-wrapper {
  @apply py-0 w-full;
}

.nav-container {
  @apply mx-0 flex items-center justify-between;
}

/* Logo Section */
.nav-logo-section {
  @apply w-full items-center;
}

.nav-logo-wrapper {
  @apply flex items-center justify-between w-full md:w-auto;
}

.nav-logo-image {
  @apply h-10 inline px-1;
}

.nav-logo-text {
  @apply inline align-middle text-gray-700 dark:text-gray-100;
}

/* Mobile Menu Button */
.nav-mobile-menu {
  @apply mr-4 flex items-center md:hidden;
}

.nav-mobile-button {
  @apply dark:bg-gray-700 bg-gray-100 rounded-md p-2 inline-flex items-center justify-center dark:text-gray-100 text-gray-700 hover:bg-cyan-500 focus:outline-none focus:ring-2;
}

/* Desktop Navigation */
.nav-desktop-links {
  @apply hidden space-x-8 md:flex md:ml-10;
}

.nav-item-base {
  @apply px-3 py-1 text-base font-medium text-gray-700 dark:text-gray-50;
}

.nav-item-active {
  @apply border-b-2 border-pink-500;
}

.nav-item-inactive {
  @apply hover:border-b-2 hover:border-b-cyan-400;
}

/* Sign In Button */
.nav-signin-wrapper {
  @apply hidden md:flex pr-4;
}

.nav-signin-button {
  @apply px-2 py-1 cursor-pointer border-2 border-solid border-cyan-300 hover:border-gray-50 text-sm font-medium rounded text-cyan-600 bg-gray-100 hover:bg-gray-100;
}

/* Mobile Menu Panel */
.nav-mobile-panel {
  @apply absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden dark:animate-color-change-2x-dark;
}

.nav-mobile-content {
  @apply rounded-lg px-6 shadow-md ring-1 ring-black ring-opacity-5 overflow-hidden;
}

.nav-mobile-header {
  @apply px-5 pt-4 flex items-center justify-between;
}

.nav-mobile-close {
  @apply rounded-md p-2 inline-flex items-center justify-center text-gray-700 dark:text-gray-50 hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600;
}

.nav-mobile-menu-items {
  @apply pt-5 pb-6;
}

.nav-mobile-links {
  @apply px-2 space-y-1;
}

/* Main Navigation */
.nav-header {
  @apply sticky top-0 z-30;
}

.nav-wrapper {
  @apply py-0 px-0;
}

.nav-container {
  @apply mx-auto flex items-center justify-between sm:pl-3 py-2;
}

/* Logo Section */
.nav-logo-section {
  @apply flex items-center flex-1;
}

.nav-logo-wrapper {
  @apply flex items-center justify-between w-full md:w-auto;
}

.nav-logo-image {
  @apply h-10 inline px-1;
}

.nav-logo-text {
  @apply inline align-middle text-gray-700 dark:text-gray-100;
}

/* Mobile Menu Button */
.nav-mobile-menu {
  @apply mr-4 flex items-center md:hidden;
}

.nav-mobile-button {
  @apply dark:bg-gray-700 bg-gray-100 rounded-md p-2 inline-flex items-center justify-center dark:text-gray-100 text-gray-700 hover:bg-cyan-500 focus:outline-none focus:ring-2;
}

/* Desktop Navigation */
.nav-desktop-links {
  @apply hidden space-x-8 md:flex md:ml-10;
}

.nav-item-base {
  @apply px-3 py-1 text-base font-medium text-gray-700 dark:text-gray-50;
}

.nav-item-active {
  @apply border-b-2 border-pink-500;
}

.nav-item-inactive {
  @apply hover:border-b-2 hover:border-b-cyan-400;
}

/* Sign In Button */
.nav-signin-wrapper {
  @apply hidden md:flex pr-4;
}

.nav-signin-button {
  @apply px-2 py-1 cursor-pointer border-2 border-solid border-cyan-300 hover:border-gray-50 text-sm font-medium rounded text-cyan-600 bg-gray-100 hover:bg-gray-100;
}

/* Mobile Menu Panel */
.nav-mobile-panel {
  @apply absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden dark:animate-color-change-2x-dark;
}

.nav-mobile-content {
  @apply rounded-lg px-6 shadow-md ring-1 ring-black ring-opacity-5 overflow-hidden;
}

.nav-mobile-header {
  @apply px-5 pt-4 flex items-center justify-between;
}

.nav-mobile-close {
  @apply rounded-md p-2 inline-flex items-center justify-center text-gray-700 dark:text-gray-50 hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600;
}

.nav-mobile-menu-items {
  @apply pt-5 pb-6;
}

.nav-mobile-links {
  @apply px-2 space-y-1;
}
/* Main Container */
.pricing-container {
  @apply pb-4;
}

.pricing-wrapper {
  @apply px-4 sm:px-6 lg:px-8;
}

.pricing-title-section {
  @apply sm:flex sm:flex-col sm:align-middle py-4;
}

.pricing-title {
  @apply mb-1 text-4xl font-bold text-gray-500 dark:text-gray-200 sm:text-center;
}

.pricing-grid {
  @apply mt-2 lg:grid lg:grid-cols-3 gap-4 lg:max-w-7xl lg:mx-auto xl:max-w-none xl:mx-0 space-y-10 lg:space-y-0;
}

/* Pricing Card Common Styles */
.pricing-card {
  @apply mx-2 border-2 rounded-lg dark:bg-gray-700 pb-2;
}

.pricing-card-header {
  @apply p-4 dark:animate-color-change-2x-dark dark:border-b-2 dark:border-b-gray-400 rounded-t dark:rounded-t-lg;
}

.pricing-card-header-title {
  @apply text-xl leading-6 text-center font-medium text-white;
}

.pricing-card-features-container {
  @apply flex flex-col justify-between;
}

.pricing-card-features-list {
  @apply ~px-2 pt-2 space-y-2 mx-auto;
}

.pricing-card-feature-item {
  @apply flex space-x-2;
}

.pricing-card-feature-icon {
  @apply flex-shrink-0 h-5 w-5 text-cyan-400;
}

.pricing-card-feature-text {
  @apply text-sm text-gray-600 dark:text-gray-300;
}

.pricing-card-action-container {
  @apply px-6 pt-2 w-full items-center;
}

.pricing-card-price-container {
  @apply mb-1 text-center;
}

.pricing-card-price {
  @apply text-xl font-extrabold text-cyan-400;
}

.pricing-card-price-label {
  @apply text-sm font-medium text-cyan-400;
}

.pricing-card-action-text-button {
  @apply align-text-bottom cursor-pointer;
}

.pricing-card-action-text {
  @apply text-xl text-gray-400 hover:text-cyan-400;
}

.pricing-card-action-link {
  @apply cursor-pointer;
}

.pricing-card-action-link-text {
  @apply text-xl text-purple-500 hover:text-purple-400;
}

/* Specific Card Color Variations */
.pricing-card-developer {
  @apply border-gray-400;
}

.pricing-card-developer-header {
  @apply bg-gray-400;
}

.pricing-card-production {
  @apply border-cyan-400;
}

.pricing-card-production-header {
  @apply bg-cyan-400;
}

.pricing-card-updates {
  @apply border-pink-400;
}

.pricing-card-updates-header {
  @apply bg-pink-400;
}
.hero-title-base {
  @apply mt-4 tracking-tight font-bold text-gray-700 dark:text-white 
         sm:mt-5 ~text-3xl/6xl sm:tracking-tight 
         lg:mt-6 xl:tracking-tight;
}

.hero-title-primary {
  @apply block text-gray-100 text-6xl;
}

.hero-title-gradient {
  @apply bg-clip-text text-transparent 
         bg-gradient-to-r from-gray-100 to-cyan-400 
         dark:from-gray-200 dark:to-pink-600 text-3xl;
}

/* Subtitle Styles */
.hero-subtitle-base {
  @apply mb-4 text-cyan-200 dark:text-gray-300 ~text-lg/xl max-w-xl;
}

/* CTA Button Styles */
.hero-cta-container {
  @apply mt-2 flex mx-20;
}

.hero-cta-wrapper {
  @apply rounded-md mt-7 shadow;
}

.hero-cta-button {
  @apply w-full flex items-center px-8 py-3 border-transparent text-base font-medium rounded-md cursor-pointer border-2 border-pink-400 hover:border-pink-300 text-white bg-pink-400 md:text-lg;
}

/* Terms of Service Styles */
.hero-terms-container {
  @apply text-xs mx-16 text-white dark:text-gray-300;
}

.hero-terms-link {
  @apply text-purple-500 cursor-pointer hover:underline;
}

/* Main Container Styles */
.hero-main-container {
  @apply px-8 py-1;
}

.hero-content-wrapper {
  @apply md:inline-flex lg:inline-flex xl:inline-flex max-w-full sm:block;
}

.hero-text-section {
  @apply mx-auto align-middle inline px-4 sm:px-6 sm:text-center lg:px-0 lg:text-left lg:items-center z-10;
}

.hero-text-inner {
  @apply ~py-12/24 sm:px-0 md:px-0 xl:px-7;
}

.hero-image-section {
  @apply mx-auto px-4 sm:px-6 lg:px-0 sm:block;
}

.hero-background-image {
  @apply sm:opacity-20 md:opacity-80 max-w-6xl sm:block;
}
/* Main Container Styles */
.overview-container {
  @apply px-4 text-center sm:px-6 lg:px-8;
}

/* Text Content Wrapper */
.overview-text-wrapper { 
  @apply mx-20;
}

/* Subtitle Styles */
.overview-subtitle {
  @apply text-xl font-semibold text-pink-500 dark:text-cyan-300;
}

/* Main Title Styles */
.overview-main-title {
  @apply mt-2 text-3xl font-bold text-gray-600 dark:text-gray-200 
         tracking-tight sm:text-4xl sm:tracking-tight;
}

/* Description Styles */
.overview-description {
  @apply mt-2 max-w-prose mx-auto text-xl text-gray-400;
}

/* Screenshot Container Styles */
.overview-screenshot-container {
  @apply mt-12 -mb-10 sm:-mb-24 lg:-mb-64;
}

/* Screenshot Styles */
.overview-screenshot {
  @apply rounded-lg shadow-xl ring-1 ring-black ring-opacity-5;
}
/* Main Container Styles */
.tos-container {
  @apply animate-in  fade-in text-xs text-gray-500 dark:text-cyan-300 dark:bg-gray-600 rounded-sm p-2;
}

/* Title Styles */
.tos-title {
  @apply text-xl font-bold;
}

.tos-subtitle {
  @apply mt-2;
}

.tos-section-title {
  @apply font-bold mt-2;
}

/* Content Styles */
.tos-content {
  @apply mt-2;
}

.tos-contact-link {
  @apply mb-0;
}

/* Modal Container Styles */
.tos-modal-container {
  @apply flex items-end justify-center
         pt-4 px-4 pb-20 text-center 
         sm:block sm:p-0;
}

.tos-modal-overlay {
  @apply fixed inset-0 bg-gray-500 bg-opacity-75 
         transition-opacity;
}

.tos-modal-content {
  @apply relative inline-block align-bottom items-center w-3/4 animate-color-change-2x dark:animate-color-change-2x-dark rounded-lg text-left shadow-xl transform transition-all sm:align-middle p-4 overflow-auto;
}

.tos-modal-inner {
  @apply px-1 inline-flex items-center;
}

.tos-modal-body {
  @apply animate-color-change-2x 
         dark:animate-color-change-2x-dark 
         px-2 pb-2;
}

.tos-logo {
  @apply h-16 mx-auto w-auto;
}

.tos-logo-text {
  @apply mt-0 text-center text-xl font-bold 
         text-gray-600 dark:text-gray-200;
}

.tos-close-button {
  @apply mt-6 w-full inline-flex justify-center rounded-sm shadow-sm py-2 bg-pink-400 text-base font-medium text-gray-100 hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-300 sm:mt-0 sm:col-start-1 sm:text-sm;
}

/* Main container */
.features-container {
  @apply relative items-center;
}

/* Features section */
.features-section {
  @apply pb-10 mb-20;
}

/* Section header */
.section-header {
  @apply text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl;
}

.section-title {
  @apply text-xl font-semibold text-pink-500 dark:text-cyan-300;
}

.section-subtitle {
  @apply mt-2 text-2xl md:text-3xl font-bold text-pink-400 dark:text-gray-200 tracking-tight sm:text-4xl sm:tracking-tight;
}

.section-subtitle span {
  @apply mx-2;
}

.section-description {
  @apply mb-10 mx-auto max-w-prose text-lg md:text-xl text-gray-500;
}

.section-description span {
  @apply mx-2;
}

/* Features grid */
.features-grid {
  @apply mx-auto px-2 grid gap-6 md:gap-8 sm:px-6 lg:px-8 grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4;
}

/* Feature card */
.feature-card {
  @apply flex flex-col rounded-lg ring-2 ring-gray-500 dark:ring-cyan-400 overflow-hidden cursor-pointer;
}

.feature-image-container {
  @apply flex-shrink-0;
}

.feature-image {
  @apply h-36 w-full object-cover;
}

.feature-content {
  @apply flex-1 p-4 sm:p-6 flex flex-col justify-between;
}

.feature-text {
  @apply flex-1;
}

.feature-category {
  @apply text-sm font-medium text-pink-500 dark:text-cyan-400;
}

.feature-link {
  @apply block mt-2;
}

.feature-title {
  @apply text-lg sm:text-xl font-semibold text-gray-700 dark:text-gray-200;
}

.feature-preview {
  @apply text-sm sm:text-base text-gray-500 dark:text-gray-400;
}

/* Quote section */
.quote-section {
  @apply bg-cyan-500 bg-gradient-to-r from-cyan-300 lg:z-10;
}

.quote-container {
  @apply lg:mx-auto lg:max-w-7xl lg:px-8 grid grid-cols-1 md:grid-cols-3 gap-4 lg:grid lg:grid-cols-3 lg:gap-8;
}

.image-container {
  @apply md:col-span-1 py-4 md:py-0;
}

.image-wrapper {
  @apply mx-auto px-4 p-0 lg:h-full;
}

.cool-image {
  @apply mx-auto max-h-48 sm:max-h-64 md:max-h-72;
}

.quote-content {
  @apply md:col-span-2 lg:m-0 lg:pl-8;
}

.quote-text-container {
  @apply mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 mt-4 md:mt-8 lg:max-w-none;
}

.quote-text {
  @apply mt-4 text-base sm:text-lg md:text-xl lg:text-2xl font-medium text-white;
}

.quote-mark {
  @apply h-6 w-6 inline m-3 text-white opacity-25;
}

.quote-mark.rotate {
  @apply rotate-180;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .quote-container {
    @apply flex flex-col;
  }
  
  .image-container {
    @apply order-2;
  }
  
  .quote-content {
    @apply order-1 pb-6;
  }
}

/* Make the main container full viewport height */
.product-main {
  @apply min-h-screen;
}

/* Base section styles for all sections */
.home-section,
.overview-section,
.features-section,
.pricing-section,
.contact-section {
  @apply min-h-screen flex flex-col justify-center;
  padding-top: 10px;
  margin-bottom: 0;
}

.section-content {
  @apply flex-1 flex flex-col justify-center items-center;
  width: 100%;
}

.overview-screenshot-container {
  margin-bottom: 0 !important;
}

@supports (-webkit-touch-callout: none) {
  .home-section,
  .overview-section,
  .features-section,
  .pricing-section,
  .contact-section {
    min-height: -webkit-fill-available;
  }
}

@media (max-height: 600px) {
  .home-section,
  .overview-section,
  .features-section,
  .pricing-section,
  .contact-section {
    min-height: 600px;
  }
}
